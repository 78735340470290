<template>
  <div class="custom-select-filter">
    <div v-if="params.filterName" class="custom-select-filter__title">
      {{ params.filterName }}
    </div>
    <div v-if="params.selectOptions">
      <custom-select-2
        name="custom-select-filter"
        v-model="filterSelect"
        :options="!params.selectSettings ? ['', ...params.selectOptions] : null"
        :inputWidth="100"
        :settings="params.selectSettings ? params.selectOptions : null"
      />
    </div>
    <button
      v-if="filterSelect"
      @click="filterSelect = ''"
      class="btn btn-w btn-sm"
    >
      Очистить
    </button>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import CustomSelect2 from "../../Forms/Fields/CustomSelect2.vue";
export default {
  components: { CustomSelect2 },
  setup(props) {
    const filterSelect = ref(null);

    const doesFilterPass = (params) => {
      const { api, colDef, column, columnApi, context } = props.params;
      const { node } = params;

      let passed = true;
      const filter = filterSelect.value.toLowerCase();

      const value = props.params.valueGetter({
        api,
        colDef,
        column,
        columnApi,
        context,
        data: node.data,
        getValue: (field) => node.data[field],
        node,
      });

      if (value.toString().toLowerCase() !== filter) {
        passed = false;
      }

      return passed;
    };

    const isFilterActive = () => {
      return filterSelect.value != null && filterSelect.value !== "";
    };

    const getModel = () => {
      if (!isFilterActive()) {
        return null;
      }

      return { value: filterSelect.value };
    };

    const setModel = (model) => {
      filterSelect.value = model == null ? null : model.value;
    };

    watch(filterSelect, () => {
      props.params.filterChangedCallback();
    });

    return {
      filterSelect,
      doesFilterPass,
      isFilterActive,
      getModel,
      setModel,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-select-filter {
  padding: 5px 5px 15px 20px;
  background-color: white;
  border: 1px solid var(--cream-color);
  border-radius: 3px;

  .form-group {
    min-width: 350px;
    margin: 10px 0;
  }

  &__title {
    font-size: 15px;
    font-weight: 400;
    padding-top: 8px;
  }
}
</style>